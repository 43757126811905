import React from "react"
import tw, { styled } from "twin.macro"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"

const MapIframe = styled.iframe`
  ${tw`w-full max-w-screen-md h-64`}
`

const Localization = () => {
  return (
    <Layout>
      <Seo title="Lokalizacja" />
      <ContentContainer>
        <TextContainer>
          <h1>Lokalizacja</h1>
          <p>
            Zajęcia odbywają się przy ul. Polna 1c/3, 55-010 Święta Katarzyna.
          </p>
        </TextContainer>
      </ContentContainer>
    </Layout>
  )
}

export default Localization
